import * as React from 'react'
import { ReactNode } from 'react'
import './accordion-item.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'

export const AccordionItem: React.FC<{ label: string; body?: ReactNode }> = ({ label, body }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false)

  if (!body) {
    return <div className="accordion-item">{label}</div>
  }

  return (
    <>
      <button
        type="button"
        className={`accordion-item accordion-item-expandable accordion-item-${expanded ? 'expanded' : 'collapsed'}`}
        onClick={() => setExpanded(!expanded)}
      >
        <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} opacity={0.5} size="xs" />
        {label}
      </button>
      {expanded && <div className="accordion-item-body">{body}</div>}
    </>
  )
}
