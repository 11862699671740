import * as React from 'react'
import Marquee from 'react-fast-marquee'
import { DefaultLayout } from '../layouts/main-layout'
import imgCapitol from '../../static/images/stock/capitol.jpg'
import imgContractDod from '../../static/images/logos/contract-dod.png'
import imgContractNavy from '../../static/images/logos/contract-navy.png'
import imgContractArmy from '../../static/images/logos/contract-army.png'
import imgContractKr from '../../static/images/logos/contract-kr.png'
import imgContractGsa from '../../static/images/logos/contract-gsa.png'
import imgContractUscis from '../../static/images/logos/contract-uscis.png'
import imgContractUsSoc from '../../static/images/logos/contract-ussoc.png'
import imgContractTsa from '../../static/images/logos/contract-tsa.png'
import imgContractUsaf from '../../static/images/logos/contract-af.png'
import imgContractUsIc from '../../static/images/logos/contract-ic.png'
import imgContractJaic from '../../static/images/logos/contract-jaic.png'
import imgContractUsda from '../../static/images/logos/contract-usda.png'
import imgContractVa from '../../static/images/logos/contract-va.png'
import imgContractLevelup from '../../static/images/logos/contract-levelup.png'
import imgContractAfNwc from '../../static/images/logos/contract-afnwc.png'
import { AccordionItem } from '../components/accordion/accordion-item'

const SPONSORS = [
  { title: 'U.S. Department of Defense', image: imgContractDod },
  { title: 'U.S. Navy', image: imgContractNavy },
  { title: 'U.S. Army', image: imgContractArmy },
  {
    title: 'U.S. Air Force Kessel Run',
    image: imgContractKr,
    imageClass: 'invert-on-high-contrast',
  },
  { title: 'General Services Administration', image: imgContractGsa },
  {
    title: 'U.S. Citizenship and Immigration Services',
    image: imgContractUscis,
  },
  { title: 'U.S. Special Operations Command', image: imgContractUsSoc },
  { title: 'Transportation Security Administration', image: imgContractTsa },
  { title: 'U.S. Air Force', image: imgContractUsaf },
  { title: 'U.S. Intelligence Community', image: imgContractUsIc },
  { title: 'Joint Artificial Intelligence Center', image: imgContractJaic },
  { title: 'U.S. Department of Agriculture', image: imgContractUsda },
  { title: 'U.S. Department of Veterans Affairs', image: imgContractVa },
  { title: 'LevelUp Code Works', image: imgContractLevelup },
  { title: 'U.S. Air Force Nulear Weapons Center', image: imgContractAfNwc },
]

interface ContractVehicleInfo {
  name: string | React.ReactNode
  description?: React.ReactNode
}

const CONTRACT_VEHICLES: ContractVehicleInfo[] = [
  { name: <>Defense Intelligence Agency (DIA) Solutions for Information Technology Enterprise III (SITE&nbsp;III)</> },
  { name: 'Air Force Software DevSecOps Services BOA' },
  { name: 'Air Force Eglin Wide Agile Acquisition Contract (EWAAC)' },
  { name: 'GSA Multiple Award Schedule (MAS)' },
  { name: 'Navy SeaPort-NxG' },
  { name: 'CIO SP3 Small Business (Joint Venture)' },
  { name: 'CIO SP3 SDVOSB (Joint Venture)' },
  { name: 'Army ITES-3S (Joint Venture)' },
  { name: <>PCI-BridgePhase Joint Venture, a Certified Native American Tribal Super&nbsp;8(a)</> },
  {
    name: 'USDA STRATUS Cloud BOA Pool 2',
    description: (
      <ul>
        <li>
          <b>STRATUS Contract Number:</b> 12314424G0024
        </li>
        <li>
          <b>STRATUS Team Members:</b> BridgePhase, LLC
        </li>
        <li>
          <b>Awarded STRATUS Task Orders:</b> n/a
        </li>
        <li>
          <b>STRATUS Contract Administration Point of Contact:</b>
          <ul>
            <li>JP Foley</li>
            <li>jpfoley@bridgephase.com</li>
            <li>703.623.2944</li>
          </ul>
        </li>
        <li>
          <b>Last updated date:</b> 6/27/24
        </li>
      </ul>
    ),
  },
  { name: <>Air Force Architecture and Integration Directorate IDIQ (AFLCMC/XA&nbsp;MAC&nbsp;IDIQ)</> },
]

const ContractVehiclesPage = () => (
  <DefaultLayout name="Contract Vehicles" description="BridgePhase contract vehicles.">
    <h1 className="page-header">Contract Vehicles</h1>

    <section className="responsive-container">
      <div
        className="img-content"
        style={{ backgroundImage: `url(${imgCapitol})` }}
        title="United States Capitol Building"
      />
      <div className="main-content">
        <p>BridgePhase is a small business that holds the following Federal Government Prime contract vehicles:</p>

        <div>
          {CONTRACT_VEHICLES.map((vehicle) => (
            <AccordionItem label={vehicle.name} body={vehicle.description} />
          ))}
        </div>

        <p className="mt-4">
          BridgePhase also works on several agency specific contract vehicles, through both Prime and Subcontracts.
        </p>
      </div>
    </section>

    <section className="py-0 accent header-section">
      <h2>Customer Experience</h2>
    </section>
    <section className="accent px-0">
      <Marquee gradient={false} speed={50} pauseOnClick={true}>
        {SPONSORS.map((contractVehicle) => (
          <div key={contractVehicle.title} className="supported-org">
            <img src={contractVehicle.image} className={contractVehicle.imageClass} />
          </div>
        ))}
      </Marquee>
    </section>
  </DefaultLayout>
)

export default ContractVehiclesPage
